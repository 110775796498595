import * as React from "react";

type Props = {
  size?: number;
  fill?: string;
  className?: string;
};

function OutlineLogo({ size = 32 }: Props) {
  return <img src="/images/autodoc-80.png" width={size} />;
}

export default OutlineLogo;
